// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-index-tsx": () => import("./../../../src/pages/archive/index.tsx" /* webpackChunkName: "component---src-pages-archive-index-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-itakepictures-tsx": () => import("./../../../src/pages/projects/itakepictures.tsx" /* webpackChunkName: "component---src-pages-projects-itakepictures-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-blog-post-blog-post-tsx": () => import("./../../../src/templates/BlogPost/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx" */),
  "component---src-templates-i-take-pictures-post-i-take-pictures-post-tsx": () => import("./../../../src/templates/ITakePicturesPost/ITakePicturesPost.tsx" /* webpackChunkName: "component---src-templates-i-take-pictures-post-i-take-pictures-post-tsx" */)
}

